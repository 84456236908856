import React, { Component } from 'react';

export default class Home extends Component {
  state = {}
  meta = {}

  render = () => {
    return (
      <div className="homepage">
      </div>
    )
  }
}